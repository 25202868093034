@import 'bootstrap/bootstrap.scss';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'mixin';
@import 'typo';
@import 'variable';
@import 'animation';
@import 'components';
@import 'pages';
@import 'modals';
@import 'reset';


body {
    padding-top: 64px;
}

.w-90 {
    width: 90%;
}

a {
    cursor: pointer !important;
}

/*Login Page*/

.wellcome-tag {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20pt;
}

.login-tag {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.neon {
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: #ff644e;
    -moz-text-shadow: 0 0 20px #ff005b;
    -o-text-shadow: 0 0 20px #ff005b;
    -ms-text-shadow: 0 0 20px #ff005b;
    -webkit-text-shadow: 0 0 20px #ff005b;
    text-shadow: 0 0 20px #ff005b;
}

.neon:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 60px;
    z-index: -1;
    color: #ff005b;
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    -webkit-filter: blur(15px);
    filter: blur(15px);
}

.neon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff0059;
    z-index: -2;
    opacity: .5;
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
    -webkit-filter: blur(40px);
    filter: blur(40px);
}

.neon-term-text {
    -moz-transform: translate(-1%, -1%);
    -o-transform: translate(-1%, -1%);
    -ms-transform: translate(-1%, -1%);
    -webkit-transform: translate(-1%, -1%);
    transform: translate(-1%, -1%);
    color: white;
    -moz-text-shadow: 0 0 10px #ff4400a1;
    -o-text-shadow: 0 0 10px #ff4400a1;
    -ms-text-shadow: 0 0 10px #ff4400a1;
    -webkit-text-shadow: 0 0 10px #ff4400a1;
    text-shadow: 0 0 10px #ff4400a1;
}

.neon-term-text:hover {
    color: #ff4400a1;
}

.neon-term-text:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 60px;
    z-index: -2;
    opacity: .3;
    color: #ff4400db;
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    -webkit-filter: blur(15px);
    filter: blur(15px);
}

.neon-term-text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff4400a1;
    z-index: -2;
    opacity: .4;
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
    -webkit-filter: blur(40px);
    filter: blur(40px);
}

.neon-purple {
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 18px;
    color: rgb(148, 55, 255);
    -moz-text-shadow: 0 0 20px rgb(148, 55, 255);
    -o-text-shadow: 0 0 20px rgb(148, 55, 255);
    -ms-text-shadow: 0 0 20px rgb(148, 55, 255);
    -webkit-text-shadow: 0 0 20px rgb(148, 55, 255);
    text-shadow: 0 0 20px rgb(148, 55, 255);
}

.login-wrapper {
    background: url(../images/login-bg.jpg) 50% 50%/cover no-repeat;

    .min-container {
        max-width: 440px;
    }

    .login-block-footer {
        background-color: #fbfaf6;
        @extend .align-items-center;
        padding: 10px 20px;
        border-radius: 0 0 3px 3px;

        span {
            font-size: $baseSize - 3;
        }

        img {
            height: 30px;
            @extend .mr-2;
        }
    }
}

.login-block {
    background-color: #fbfaf6;
    @extend .position-relative;

    &:after {
        -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20px;
    }

    h1 {
        margin: 40px 0 10px 0;

    }

    p {
        @extend .mb-4;
    }

    a {
        font-size: 14px;

        &:hover {
            text-decoration: none;
        }
    }

    a:not(.forgot) {
        color: $bodytextcolor;

        i {
            vertical-align: middle;
        }
    }

    .forgot {
        color: rgba(0, 0, 0, 0.7);
        @include basecolor(0.7);
        text-decoration: underline;
        text-decoration-color: rgba(0, 0, 0, 0.2);
    }

    .btn-primary {
        background: #39b27b;
        height: 48px;
        padding: 0 24px;
        color: #fff;
        font-size: $baseSize;
        @extend .border-0;
        @extend .d-inline-flex;
        @extend .align-items-center;

        i {
            font-size: $baseSize;
            margin-left: 20px;
        }
    }

    .dropdown {
        position: absolute;
        right: 20px;
        bottom: 20px;

        .dropdown-menu {
            min-width: 100%;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            border: none;

            a {
                padding: 4px 10px;
                font-size: 13px;
                text-decoration: none;
            }
        }

        .dropdown-toggle {
            border-radius: 3px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            background-color: #fff;
            border-color: #fff;
            color: $bodytextcolor;
            padding: 0 10px;
            height: 40px;
            font-size: 13px;
            @extend .d-inline-flex;
            @extend .align-items-center;

            &:focus,
            &:active {
                box-shadow: none;
                outline: none;
            }

            &:after {
                border: none;
                margin: 0;
            }

            .arrow {
                color: #979694;
            }
        }
    }
}

.notification {
    //position: fixed;
    //right: 20px;
    //bottom: 20px;
    width: 320px;
    max-width: 100%;

    .notification-block {
        border-radius: 3px;
        background-color: #fbfaf6;
        padding: 30px 20px 10px;
        border-top: 5px solid $activeBgColor;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        h3,
        p {
            margin-bottom: 20px;
        }

        p {
            font-size: $baseSize - 1;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0 -20px;

            li {
                font-size: $baseSize - 1;
                color: $bodytextcolor;
                padding: 8px 20px 8px 44px;
                cursor: pointer;
                position: relative;

                &:hover {
                    background: #eeede9;

                    &:before {
                        color: $activeBgColor;
                    }
                }

                &:before {
                    content: "arrow_forward";
                    font-family: 'Material Icons';
                    position: absolute;
                    left: 16px;
                    top: 8px;
                    color: #979694;
                }
            }
        }
    }
}

.modal-backdrop {
    opacity: 0.6;
}

.coypright-modal,
.notifcation-modal {
    .modal-dialog {
        max-width: 600px;
    }

    .modal-content {
        @extend .p-0;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.32);
    }

    .modal-header {
        .close {
            position: absolute;
            right: 0;
            bottom: 100%;
            color: #fff;
            font-weight: 300;
            line-height: 30px;

            &:hover {
                color: #fff;
            }

            &:focus {
                outline: none;
            }
        }
    }
}

.notifcation-modal {
    .modal-header {
        @extend .border-0;
        padding: 30px 30px 0;

        h1 {
            line-height: 1.25;
            letter-spacing: -0.5px;
        }
    }

    .notifcation-content {
        padding: 30px 30px 0;
    }

    .banner {
        padding-top: 30px;

        img {
            width: 100%;
        }
    }

    .modal-body {
        padding: 0;

        p {
            margin-bottom: 30px;
            font-size: $baseSize - 1;

            a {
                color: $activeBgColor;
                text-decoration: underline;
                text-decoration-color: rgba(0, 153, 255, 0.3);
            }
        }
    }

    .media {
        padding: 30px 30px 0;

        img {
            width: 36px;
        }

        .media-body {
            margin-left: 10px;

            span {
                display: block;
                font-size: $baseSize - 1;
                color: #4b4b4a;
                font-weight: 500;
            }

            small {
                color: #7e7d7b;
                font-size: $baseSize - 3;
            }
        }
    }
}

.coypright-modal {
    .modal-header {
        padding: 0;
    }

    .banner {
        padding: 60px 75px;
        background: #fbfaf6;

        img {
            width: 450px;
        }
    }

    .modal-body {
        padding: 40px 30px 14px;

        p {
            font-size: 15px;

            span {
                font-weight: 500;
            }
        }
    }
}

.notification-bell,
.hide-notification {
    width: 50px;
    height: 50px;
    display: inline-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    @extend .rounded-circle;
    cursor: pointer;
    @include slideAnimation;

    i {
        color: #fff;
        font-size: $baseSize + 4;
    }
}

.notification-bell {
    background: $activeBgColor;
    z-index: 9;
}

.hide-notification {
    background: $dangerColor;
    font-size: 24px;
    color: #fff;
    //opacity: 0;
}

.active-notification-panel {

    .notification-bell,
    .hide-notification {
        transform: translateX(-305px);
    }

    .hide-notification {
        z-index: 9;
    }
}

/*Header*/
header {
    background: url(../images/navigation-bg.jpg) no-repeat 50% 50%/cover;
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

/*Header End*/

/*Inner Dropdown Menu*/
.top-menu-dropdown {
    .container-fluid {
        position: relative;
        z-index: 999;
    }

    .tab-content {
        background: #fbfaf6;
    }

    &:after {
        content: "";
        position: fixed;
        top: 64px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
    }

    .sidebar-list {
        @include menu(17px, 12px 25px, rgba(0, 0, 0, 0.9), 0, block, #e2e1dd, 24px, #eeede9);
        flex-direction: column;

        li {
            &.new-notification {
                @include new-notification(20px, auto, 10px);
            }
        }

    }

    background: #fbfaf6;
    position: fixed !important;
    top:64px !important;
    left: 0 !important;
    right: 0;
    z-index: 999;
    transform: none !important;
    display: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    border: none;

    // .active {
    //     a {
    //         padding-top: 8px;
    //         padding-bottom: 8px;
    //     }
    // }
    .side-bar {
        background: #eeede9;
        padding: 32px 16px 32px;
        min-height: 100%;

        .sidebar-list {
            margin-bottom: 0;

            li:first-child {
                margin-top: 0;
            }
        }
    }
}

/*Media*/
.lecture-bars {
    padding: 32px 0 20px;

    .media {
        margin-bottom: 12px;

        .material-icons {
            position: absolute;
            right: 12px;
            top: 0;
        }
    }

    @include media;

    .active-border {
        position: absolute;
        right: 8px;
        top: 13px;
    }
}

.carousel-slide,
.lesson-header {
    .circle {
        span {
            position: relative;
        }

        .number {
            left: 4px;
            top: 5px;
        }

        .slash {
            opacity: 0.3;
            font-size: 25px;
            line-height: 32px;
            left: 2px;
            top: -4px;
        }

        .small-number {
            position: relative;
            left: 0;
            top: -7px;
            font-size: 12px;
        }
    }

    .lesson-counter {
        text-align: center;

        .counter-label {
            font-size: 11px;
            font-weight: 600;
            color: rgba(50, 50, 49, 0.3);
            line-height: 20px;
        }

        .active-border {
            margin: 0;
        }
    }

    .text-danger {
        color: $dangerColor;
    }

    .text-success {
        color: $successColor;
    }

    .counter-point {
        font-size: 12px;
    }

    @include percentCounter(54px, 54px, 60px, 60px, #eeede9);
}

/*Lesson homepage*/
.lesson-header {
    background: #eeede9;
    padding: 30px 0 23px;

    .media-body {
        span:not(.count) {
            color: rgba(50, 50, 49, 0.3);
            font-weight: 600;
            font-size: 12px;
        }

        a {
            color: rgba(50, 50, 49, 0.7);
            font-size: 13px;
            display: inline-block;
            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 1px solid #e2e1dd;
        }

        .count {
            @include circle-number(20px, auto, #e2e1dd, 14px, 400, 20px, none, 2px);
            padding: 0 6px;
            color: rgba(50, 50, 49, 0.7);
        }
    }
}

.studen-tabs {
    .tab-content {
        background: #fbfaf6;
    }
}

.lesson-tabs {
    .tab-header {
        background: #eeede9;

        .nav {
            a {
                line-height: 32px;
                display: block;
                color: rgba(50, 50, 49, 0.8);
                font-weight: 500;
                padding: 0;
                border: none;

                &:hover {
                    border: none;
                }
            }
        }

        .material-icons {
            vertical-align: middle;
        }

        li {
            &.new-notification {
                @include new-notification(0, 0, auto)
            }
        }

        a.active {
            background: none;
            border: none;
            position: relative;
        }
    }

    .tab-content {
        padding-top: 16px;
    }
}

/*Notification bar*/
.notification-bar {
    background: #fce9ab;
    border-radius: 2px;
    padding: 20px 26px;

    p {
        margin: 0;
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
        line-height: 20px;

        span {
            font-weight: 600;
        }

        a {
            color: rgba(0, 0, 0, 0.7);
            text-decoration: none;
        }
    }

    img {
        margin-right: 14px;
    }
}

/*Content box*/
.content-box {
    &.shot-content {
        position: relative;

        &:after {
            background: linear-gradient(-180deg, rgba(251, 250, 246, 0.00) 0%, #FBFAF6 100%);
            content: "";
            width: 100%;
            height: 60px;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
        }
    }

    h2 {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 0;
        opacity: 0.8;

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }
    }

    .arrow-list {
        li {
            padding-left: 32px;
            list-style: none;
            position: relative;

            &:before {
                content: "arrow_forward";
                font-family: 'Material Icons';
                position: absolute;
                left: 0;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
}

.this-week-activity {
    margin-top: 50px;
    background: #fff;
    padding: 26px 30px 35px 0;
    position: relative;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));

    &:after {
        position: absolute;
        content: "";
        width: 1000%;
        top: 0;
        bottom: 0;
        border-radius: 2px;
        box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.08);
        background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
        right: 100%;
    }

    .text-success {
        span {
            display: block;
        }

        span:not(.number) {
            font-size: 12px;
        }

        .number {
            font-weight: 300;
            font-size: 48px;
            line-height: 48px;
        }
    }

    .media-body {
        margin-left: 30px;

        .date {
            color: rgba(33, 37, 41, 0.3);
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
        }

        h4 {
            margin: 10px 0;
        }

        p {
            color: rgba(33, 37, 41, 0.8);
            line-height: 24px;
        }
    }
}

.week-points,
.upcomming-events,
.announcements {
    .points {
        margin-top: 20px;
    }
}

.week-points,
.debates-section,
.upcomming-events,
.announcements {
    margin-top: 30px;

    .count {
        @include circle-number(20px, 21px, #eeede9, 13px, 400, 20px, none, 100%)
    }

    h5 {
        font-weight: 500;
        color: #323231;
        line-height: 32px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeede9;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        a {
            color: #323231;
            text-decoration: none;
        }
    }

    .points {
        &>div:not(.circle):not(.active-border) {
            margin-left: 24px;
        }

        h6 {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            margin: 0 0 4px;
        }

        span {
            color: #b0afac;
            font-size: 14px;
        }

    }
}

.debates-section {
    .points {
        &.new-notification {
            @include new-notification(22px, auto, -18px);
        }

        position: relative;
        margin-top: 18px;

        &>div:not(.circle) {
            width: 100%;

            span {
                display: block;
                padding: 0 0 18px;
                border-bottom: 1px solid #eeede9;
            }
        }
    }

    img {
        width: 48px;
        height: 48px;
        border-radius: 100%;
    }
}

.announcements {
    .date {
        color: #b0afac;
        font-size: 13px;
        line-height: 18px;
        display: block;
        margin-top: 4px;
    }

    span {
        font-size: 15px;
        color: #7e7d7b !important;
        display: block;
    }
}

.week-points {
    @include percentCounter(40px, 40px, 0, 0, #fff);

    .circle {
        border: 1px solid #eeede9;
        transform: none;
    }
}

.upcomming-events {
    @include percentCounter(36px, 36px, 40px, 40px, #fbfaf6);

    .circle {
        img {
            height: 15px;
        }
    }
}

.select-branch {
    select {
        margin-top: 30px;
    }
}

/*Lesson homepage for teacher*/
.teacher-tabs .tab-content {
    background: #e2e1dd;
}

.carousel-slide {
    background: #fff;
    padding: 0 0 20px;
    position: relative;
    cursor: pointer;

    .block-link {
        @include positionabsolute(0, 0, 0, 0);
    }

    h4 {
        padding: 15px;
        border-bottom: 1px solid rgba(33, 37, 41, 0.2);

        img {
            opacity: 0.4;
        }
    }

    ul {
        padding: 20px 15px;
        margin: 0;
        list-style: none;
    }

    li {
        &:not(:last-child) {
            padding-bottom: 10px;
        }

        &:last-child {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(33, 37, 41, 0.1);
        }

        .number {
            float: right;
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
        }

        span:not(.number) {
            color: rgba(33, 37, 41, 0.5);
        }
    }
}

.lesson-help {
    @include white-box(#fff, 3px, 25px 15px, 0 0 16px);

    p {
        white-space: pre-line;
        font-size: 14px;
        color: #565b65;
        margin: 0;

        span {
            opacity: 0.6;
        }
    }
}

/*Lesson Detail*/
.course-background {
    position: relative;

    .row {
        position: absolute;
        bottom: 0;
        color: #fff;
        left: 0;
        right: 0;
        padding: 15px;
        font-size: 15px;
    }

    .user-name img {
        border-radius: 100%;
        margin-right: 10px;
    }

    .week,
    .date {
        font-size: 12px;
        display: block;
    }

    .date {
        opacity: 0.7;
    }

}

.discussion-content {
    overflow: hidden;

    .white-box {
        @include white-box(#fff, 2px, 0, 0 0 90px);
        overflow: hidden;
    }

    .progressed-bar {
        margin-top: 36px;
    }

    .banner p {
        margin: 0;
        padding: 12px 24px 12px;
        font-size: 14px;
        color: #656d78;
    }

    .course-footer {
        background: rgba(25, 25, 25, 0.04);

        a {
            color: #0099ff;
        }
    }
}

.progressed-bar {

    h4,
    .count {
        position: relative;
        z-index: 9;
    }

    h4 {
        display: inline-flex;
        align-items: center;
        background: #fff;
        margin: 0;
        line-height: 32px;
        padding-right: 15px;

        img {
            height: 17px;
        }
    }

    .count {
        @include circle-number(32px, auto, #dff2e9, 14px, 600, 32px, none, 32px);
        padding: 0 22px;
        overflow: hidden;
        align-items: center;

        i {
            font-size: 16px;
            margin: 0 6px;
        }

        span {
            opacity: 0.5;
        }
    }

    @include percentCounter(32px, 32px, null, null, #39b27b);

    .circle {
        transform: none;
        margin-right: 12px;

        i {
            font-size: 20px;
        }
    }
}

.quiz-section {
    ul {
        list-style: none;
        padding: 0;

        li {
            position: relative;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &.ticked,
            &.wrong {
                &:after {
                    position: absolute;
                    font: 18px/1 'Material Icons';
                    top: 8px;
                    left: -25px;
                }
            }

            &.ticked {
                &:after {
                    content: "done";
                    color: #39b27b;
                }
            }

            &.wrong {
                &:after {
                    content: "close";
                    color: #ff6f61
                }
            }

            &.ticked,
            &.wrong {
                input {
                    &~label:after {
                        content: "";
                        @include positionabsolute(0, 0, 0, 0);
                    }
                }
            }

            &.ticked {
                input {
                    &~label:after {
                        background: #39b27b;
                        opacity: 0.2;
                    }
                }
            }

            &.wrong {
                input {
                    &~label:after {
                        background: #ff6f61;
                        opacity: 0.2;
                    }
                }
            }
        }
    }

    .dot-radio {
        display: inline-flex;
        padding: 4px 12px 4px 6px;

        label {
            width: calc(100% - 21px);
            line-height: 24px;
            font-weight: 400;
        }

        input {
            margin-top: 5px;

            &:checked {
                &~label {
                    color: #323232;
                }
            }
        }
    }
}

.homework-ans {
    h6 {
        font-size: 18px;
    }

    textarea {
        width: 100%;
        padding: 12px;
        background: #eeede9;
        border: none;
        resize: none;
        border-radius: 3px;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.8);

        &:focus {
            outline: none;
        }
    }

    .text-limit {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        position: absolute;
        right: 12px;
        bottom: 6px;
    }

    .half-color {
        font-size: 13px;
    }
}

.upload-image {
    background: #eeede9;
    padding: 48px 15px 32px;
    text-align: center;
}

.upload-image.upload-in-process {
    &>* {
        &:not(.upload-percentage) {
            visibility: hidden;
        }
    }

    &.upload-in-process {
        background: rgba(57, 178, 123, 0.1);
        text-align: left;
        position: relative;

        h1 {
            font-size: 96px;
            line-height: 1.19;
            color: rgba(0, 0, 0, 0.2);
        }

        span {
            font-size: 18px;
            opacity: 0.5;
        }

        .upload-percentage {
            padding: 48px 15px 32px;
            z-index: 9;
            @include positionabsolute(0, auto, 0, 0);
            background: #39b27b;
        }
    }
}

.main-sidebar {
    background: #eeede9;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    h5 {
        img {
            margin-top: 10px;
        }
    }

    &>ul li {
        cursor: pointer;
        padding: 16px 10px 16px 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &:hover {
            background: rgba(0, 0, 0, 0.04)
        }
    }

    &>ul {

        [active="active"] {
            background: rgba(0, 0, 0, 0.08);

            &>.media {
                padding-bottom: 16px;
            }
        }

        [passive="passive"] {
            img {
                opacity: 0.4;
            }

            span,
            label {
                opacity: 0.6;
            }
        }

        [outdated="outdated"] {

            label,
            span {
                color: $dangerColor;
            }

            span {
                opacity: 0.8;
            }
        }

        [lock="lock"] {
            .material-icons {
                opacity: 0.5;
                font-size: 20px;
            }

            label,
            span {
                opacity: 0.3;
            }
        }
    }

    .course-percentage {
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        margin-top: 124px;
        background: #e2e1dd;
        padding: 10px 20px;

        .count {
            @include circle-number(20px, auto, #39b27b, 12px, 600, 20px, none, 32px);
            padding: 0 7px;
            color: #fff;
        }

        .bar {
            background: rgba(0, 0, 0, 0.05);
            height: 6px;
            border-radius: 32px;
            margin: 12px 0 16px;
            position: relative;

            .bar-width {
                @include positionabsolute(0, 0, 0, 0);
                background: #323231;
                border-radius: 32px;
            }
        }

        p {
            font-size: 12px;
            color: #656d78;
        }
    }

    @include percentCounter(32px, 32px, null, null, #39b27b);

    .circle {
        transform: none;
    }

    .inner-items {
        margin: 0 -10px 0 -16px;
        background: rgba(0, 0, 0, 0.04);
        padding: 4px;

        .media {
            padding: 16px 10px 16px 16px;

            label {
                font-size: 13px;
            }
        }

        .circle {
            width: 24px;
            height: 24px;

            i {
                font-size: 16px;
            }
        }
    }

    .media {
        align-items: center;
    }

    .modal-body {
        padding: 0 0 0 12px;

        label {
            margin: 0;
            display: block;
            font-size: 15px;
            line-height: 20px;
        }

        span {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
            line-height: 14px;
            display: block;
        }
    }
}

/*Activity Dropdown*/
.plus-dropdown {
    .dropdown {
        .dropdown-menu {
            right: 64px;
            transform: none !important;
            left: auto !important;
            margin-top: 45px;
            padding: 12px;
            border-radius: 2px;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
            background-color: #fff;
            max-width: 421px;
            border: none;
        }
    }

    .dropdown-menu {
        label {
            font-size: 12px;
            font-weight: 600;
            margin: 0;
            display: block;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0 -1px;
        }

        li {
            background: #fbfaf6;
            display: inline-block;
            width: 96px;
            text-align: center;
            border: 1px solid #fff;
            margin-top: 4px;

            &:hover {
                background: #eeede9;
            }

            a {
                text-decoration: none;
                padding: 7px 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            span {
                line-height: 20px;
                font-size: 12px;
                margin-top: 5px;
                color: rgba(0, 0, 0, 0.5);
            }

            img,
            svg {
                height: 24px;
            }

            svg g {
                fill: #333;
            }
        }

    }
}

.modal-from-right,
.announcementTabs {
    .buyer-stats-table {
        td {
            padding-top: 4px;
            padding-bottom: 4px;
            font-weight: 500;
            font-size: 14px;

            &:not(:first-child) {
                text-align: right;
            }

            .material-icons {
                color: #c9c8c5;
            }
        }
    }

    .nav-tabs {
        display: table;
        width: 100%;

        li {
            display: table-cell;
        }

        a {
            display: block;
            border: none;
            text-align: center;
            border-bottom: 3px solid #EEEDE9;
            color: $bodytextcolor;
            padding: 13px 10px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;

            &:not(.active) {
                opacity: 0.3;
                background: #EEEDE9;
            }

            &.active {
                background: $primaryBgColor;
                border-bottom-color: $activeBgColor;

            }
        }
    }
}

/*Activity Dropdown*/
/*Activity Modal*/
.modal-from-right,
.question-modal,
.message-people-list {

    .options,
    .options-bulets {
        list-style: none;
        padding: 0;

        .active {
            position: relative;

            &::after {
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: #39b27b;
                position: absolute;
                left: 46px;
                top: 3px;
                content: "";
            }
        }

        img {
            width: 32px;
            border: 2px solid #fbfaf6;
            border-radius: 100%;
        }
    }
}

.modal-from-right,
.question-modal {
    .announcement-heading {
        padding-top: 8px;
        padding-bottom: 7px;

        span {
            font-size: 11px;
            color: rgba(33, 37, 41, 0.5);
        }

        h6 {
            font-size: 14px;
        }

        a {
            color: #0099ff;
            font-size: 12px;
            text-decoration: none;
        }
    }

    .options,
    .options-bulets {
        img {
            margin: 0 14px 0 23px;
        }

        li {
            margin-bottom: 16px;
        }

        span:not(.count):not(.img-holder) {
            font-weight: 500;
            color: #191919;
            font-size: 14px;
        }

        .count {
            border-radius: 32px !important;
            font-size: 13px;
            padding: 0 6px;
            margin-left: 10px;
        }

        .img-holder {
            margin: 0 16px;
        }
    }
}

.img-holder {
    position: relative;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    display: inline-block;
    vertical-align: middle;

    &>* {
        position: absolute;
        margin: 0;

        &:first-child {
            top: 0;
        }

        &:last-child {
            bottom: 0;
            right: 0;
        }
    }
}

.modal-from-right {
    .number-count {
        @include circle-number(20px, auto, #e2e1dd, 10px, 400, 20px, none, 2px);
        padding: 0 5px;
    }

    .questions {
        min-height: auto;
    }

    .content-container .main-sidebar {
        &>ul li {
            border: none;
        }

        min-height: calc(100vh - 77px);
    }

    .modal-dialog {
        @include positionabsolute(0, 0, auto, auto);
        margin: 0;
    }

    .modal-content {
        border: none;
        border-radius: 0;
    }

    .modal-header {
        background: #E2E1DD;
        border-radius: 0;
        border: none;
        @include close-button(null, null, 16px 22px);
    }

    .modal-title {
        line-height: 31px;
        color: $bodytextcolor;
    }

    .nav-tabs {
        display: table;
        width: 100%;

        li {
            display: table-cell;
        }

        a {
            display: block;
            border: none;
            text-align: center;
            border-bottom: 3px solid #EEEDE9;
            color: $bodytextcolor;
            padding: 13px 10px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 600;

            &:not(.active) {
                opacity: 0.3;
                background: #EEEDE9;
            }

            &.active {
                background: $primaryBgColor;
                border-bottom-color: $activeBgColor;

            }
        }
    }

    .modal-body {
        min-height: calc(100vh - 63px - 77px);
        background: $primaryBgColor;
    }

    .modal-footer {
        background-color: $primaryBgColor;
    }
}

.multioptions {
    background: #eeede9;
    padding: 16px 16px 56px 16px;
    margin-top: 14px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li:not(:last-child) {
        margin-bottom: 16px;
    }
}

.content-box.black-bg {
    color: #fff;

    ul {
        margin: 0 -15px;
    }

    li {
        padding: 15px;
        margin: 0 !important;
    }

    .tick-radio .form-radio:checked {
        background: #fff !important;
        border-color: #fff;

        &:before {
            color: #333;
        }
    }

    label {
        color: #fff !important;
    }

    .circle {
        @include circle-number(72px, 72px, none, 24px, normal, 82px, 1px solid, 100%);

        &.danger-cricle {
            color: $dangerColor;
            border-color: $dangerColor;
        }

        &.success-cricle {
            color: $successColor;
            border-color: $successColor;
        }

        i {
            font-size: 30px;
            line-height: 38px;
        }
    }
}

.video-statistics {
    max-width: 450px;
    margin: 0 auto;

    ul {
        list-style: none;

        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            h4,
            span {
                margin-top: 8px;
            }
        }
    }

    &>h4 {
        border-bottom: 3px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 10px;
    }

    span {
        display: inline-block;
        opacity: 0.8;
        line-height: 24px;
    }
}

.content-box.black-bg {
    .video-statistics {

        ul,
        li {
            padding: 0;
            margin: 0;
        }
    }
}

.aperture {
    background: rgba(0, 0, 0, 0.6);
    padding: 30px;

    .aperture-setting {
        background: #fbfaf6;

        &> {
            .media {
                padding: 20px;
            }
        }

        .aperture-table {
            padding: 20px 0;
        }

        table {
            tr {
                &:first-child {
                    td {
                        padding-top: 14px;
                    }
                }
            }

            th,
            td {
                &:nth-child(2) {
                    div {
                        width: 174px;
                        display: inline-flex;
                        align-items: flex-end;
                    }
                }
            }

            th {
                font-size: 14px;
                font-weight: 500;
                padding-top: 12px;
                border-top: none;
                border-bottom: 2px solid #dee2e6;
            }

            th,
            td {
                color: #323231;

                &:first-child {
                    padding-left: 20px;
                }

                &:nth-child(2) {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                    padding-right: 20px;
                }
            }

            td {
                border: none;
                font-size: 14px;
                vertical-align: middle;
                background: #eeede9;
                padding-top: 8px;
                padding-bottom: 8px;

                &:nth-child(2) {
                    div {
                        letter-spacing: 2px;

                        span,
                        small {
                            color: #979694;
                        }

                        small {
                            font-size: 12px;
                        }

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }

            tr:nth-of-type(odd) td {
                background: #fbfaf6;
            }
        }
    }
}

/* Anouncement Section */
.create-announcement {
    padding-top: 60px;

    p {
        font-size: 15px;
        color: #646462;
    }
}

.main-sidebar {
    @include search-and-options(#646462, #dbdad7);

    .search {
        @include positionabsolute(11px, 12px, auto, 14px);
    }
}

.announcementTabs {
    .nav-item {
        position: relative;

        .nav-link {
            border-color: #e2e1dd;
        }
    }

    .count {
        @include circle-number(14px, 14px, $successColor, 9px, 500, 14px, none, 100%);
        color: #fff;
        position: absolute;
        right: 3px;
        top: 3px;
    }

    .media {
        padding: 16px;
        align-items: flex-start;
        cursor: pointer;

        &>img {
            width: 32px;
        }

        &.active {
            background: #fbfaf6;
            border-right: 1px solid #eeede9;
        }
    }

    .media-body {
        margin-left: 20px;

        h5 {
            font-size: 15px;
            font-weight: 600;
            color: #323232;
        }

        h6 {
            font-size: 14px;
            color: #979694;
            font-weight: 400;
            line-height: 18px;
            margin: 0;

            span {
                color: #323232;
            }
        }
    }

    .announcement-type {
        color: #979694;
        font-size: 12px;
    }

    // .name-letter {
    //     width: 32px;
    //     height: 32px;
    //     background: #4b4b4a;
    //     border-radius: 100%;
    //     line-height: 32px;
    //     text-align: center;
    //     color: #fff;
    //     font-size: 14px;
    //     font-weight: 600;
    // }
}

/*Announcement Detail*/
.announcement-heading {
    border-bottom: 1px solid #e2e1dd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 17px;

    h6 {
        margin: 0;
    }
}

.announcement-detail-content,
.stats-row {

    .announcement-stats,
    .stats-col {
        @include percentCounter(28px, 28px, 32px, 32px, #fbfaf6);
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .circle {
            small {
                font-size: 10px;
            }

            font-size: 12px;
            color: rgba(50, 50, 49, 0.5);
        }
    }
}

.announcement-detail-content {
    background: #fbfaf6;
    min-height: 100vh;

    .user-info {
        padding: 26px 0 0;
        align-items: center;

        &>* {
            margin-top: 10px;
        }

        span {
            color: rgba(50, 50, 49, 0.5);
        }

        i {
            color: #b0afac;
            font-size: 18px;
        }

        img {
            width: 32px;
            height: 32px;
        }

        .category-tag-circle {
            margin-left: 4px;
        }
    }

    .date {
        color: #979694;
        font-size: 12px;
    }

    .announcement-content {
        margin-top: 20px;

        p,
        li {
            font-size: 15px;

            a {
                color: $activeBgColor;
            }
        }

        ul li {
            color: #4b4b4a;
        }
    }

    .upload-files-name {
        @include upload-file-button();
    }

    .upload-file-display {
        overflow: hidden;
        margin-top: 10px;

        .row {
            margin: 0 -7px;

            &>[class*="col-"] {
                padding: 0 7px;
                margin-top: 14px;
            }
        }

        img {
            width: 100%;
        }
    }

    .button-options {
        button {
            margin-top: 10px;
        }
    }
}

.announcement-modal {
    .modal-dialog {
        max-width: 730px;
    }

    .modal-header {
        padding: 0;
        border: none;
    }

    .modal-body {
        padding: 0;
    }

    .modal-content {
        padding: 30px;
        background: #fbfaf6;
    }
}

.stats-blocks {
    h6 {
        font-size: 18px;
        margin: 0;
        font-weight: 500;
        text-transform: uppercase;
    }

    .card {
        margin-top: 20px;
        border: none;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    }

    .card-header {
        padding: 17px 5px;
        text-align: center;
        border: none;
    }

    .card-body {
        padding: 20px 15px;
    }

    .stats-value {
        display: block;
        line-height: 24px;
    }

    .counter-label,
    .stats-value {
        color: #323231;
        opacity: 0.5;
        font-size: 14px;
    }

    .report-counter {
        text-align: center;

        .counter-label {
            font-size: 11px;
        }

        @include percentCounter(54px, 54px, 60px, 60px, #eeede9);
    }
}

.buyer-stats {
    margin-top: 45px;
    @include search-and-options(#646462, none);

    .search {
        @include positionabsolute(-4px, 12px, auto, 14px);
    }

    .form-control {
        padding-right: 26px;
    }

    .options {
        a {
            font-size: 14px;
        }
    }
}

.buyer-stats-table {
    margin: 0px -30px;

    th {
        padding: 10px 5px;
        font-size: 14px;
        font-weight: 500;
        border-top: none;
    }

    th:last-child,
    td:last-child {
        padding-right: 30px;
    }

    th:first-child,
    td:first-child {
        width: 60%;
        padding-left: 30px;
    }

    td {
        padding: 4px 5px;
        border: none;
        vertical-align: middle;
    }

    tr:first-child td {
        padding-top: 14px;
    }

    td:first-child {
        span {
            font-size: 14px;
        }
    }

    td:not(:first-child),
    th:not(:first-child) {
        text-transform: uppercase;
        text-align: center;
    }

    .material-icons {
        font-size: 18px;
        margin-top: 7px;
    }

    .gray-tick {
        color: #979694;
    }

    .blue-tick {
        color: #0099ff;
    }

    tbody {
        tr:nth-of-type(even) {
            background: #eeede9;
        }
    }
}

.add-announcement {
    .upload-image {
        margin-top: 30px;
    }
}

.attached-file {
    background: #eeede9;
    padding: 12px 10px;
    margin-top: 30px;
    cursor: pointer;

    p {
        font-size: 14px;
        margin: 0;
        color: #646462;
    }

    .material-icons {
        font-size: 22px;
        color: #646462;
    }

    a {
        color: $activeBgColor;
    }

    .uploaded-file {
        background: #fff;

        p {
            color: #323231;
        }
    }

    .uploading-file {
        background: #fbfaf6;
        //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
        position: relative;

        p {
            color: #c9c8c5;
        }

        &:after {
            content: "";
        }
    }

    .uploaded-file,
    .uploading-file {
        padding: 6px 10px;
        margin-bottom: 8px;

        i {
            font-size: 18px;
        }

        span,
        i {
            color: #979694;
        }

        span {
            margin-left: 8px;
            display: inline-block;
        }
    }

    @include percentCounter(null, null, null, null, null, true);
}

.add-session-row {
    padding: 10px 10px 10px 16px;
    background: #fbfaf6;
    margin-top: 10px;

    span {
        color: rgba(33, 37, 41, 0.5);
        font-size: 14px;
    }

    h6 {
        font-size: 15px;
        color: rgb(33, 37, 41);
        margin: 0;
    }
}

/*QUESTION LIST*/
.questions {
    background: #fbfaf6;
    min-height: 100vh;
}

.question-filter,
.exam-modal-stats {
    .tag-gray {

        &[aria-expanded="true"] {
            i {
                transform: rotate(180deg);
            }

            .open {
                display: block;
            }
        }

        &[aria-expanded="false"] {
            .closed {
                display: block;
            }
        }
    }
}

.question-filter .options,
.conversation-window .conversation-header {
    .dropdown-menu {
        border: none;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);

        .dropdown-item:hover {
            background: #eeede9;
            color: #323231;
        }
    }
}

.question-filter {
    padding: 20px 0 0;
    @include search-and-options(#7e7d7b, #fbfaf6);

    span {
        display: none;
    }

    .search {
        @include positionabsolute(0, 12px, auto, 14px);
    }

    .options {
        .dropdown-menu {
            left: auto !important;
            right: 0;
        }
    }
}

.advance-search {
    display: none;
}

.filter-fields {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
    padding: 15px 15px 0;
}

.page-sorting {
    color: #b0afac;
    font-size: 14px;
}

.exam-modal-stats {
    .white-box {
        padding: 20px;
        background: #fff;
        margin-top: 20px;

        h6 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 32px;
            margin-bottom: 12px;

            span,
            i {
                font-size: 15px;
            }

            span {
                display: flex;
                align-items: center;
            }

            i {
                color: #979694;
            }
        }

        table {
            margin-bottom: 0;
        }

        td {
            font-size: 14px;
            line-height: 24px;
            padding: 10px 0;
            border-color: #eeede9;

            &:first-child {
                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    margin-right: 16px;
                }
            }

            &:last-child {
                text-align: right;
            }
        }

        tbody {
            td {
                color: #323231;
            }

            tr:first-child td {
                border-top: none;
            }
        }

        tfoot td {
            border-top: 2px solid #e2e1dd;
            font-weight: 600;
        }
    }

    .buyer-stats .search {
        top: -3px;
        right: 0;
        bottom: auto;
        left: -36px;
    }

    .stats-bar-block {
        .exam-points-table.table {
            background: #eeede9;
            border-radius: 4px;

            td {
                border: none;
                padding: 10px;

                &:first-child {
                    font-weight: 400;
                }
            }

            th {
                font-size: 14px;
                font-weight: 600;
                padding: 10px;

                &:last-child {
                    text-align: right;
                }
            }
        }

    }
}

.question-modal,
.exam-modal-stats,
.exam-miss-modal {
    .modal-content {
        padding: 30px 30px 20px;
        background: #fbfaf6;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.32);
    }

    .modal-header {
        padding: 0 0 10px;

        h6,
        i {
            font-size: 14px;
            color: #646462;
        }

        .close {
            font-weight: normal;
        }
    }

    @include percentCounter(28px, 28px, 32px, 32px, #fbfaf6);

    .circle {
        font-size: 12px;
        color: rgba(50, 50, 49, 0.5);

        small {
            font-size: 10px;
        }
    }

    textarea {
        margin: 20px 0 0;
        border: none;
        background: #eeede9;
        border-radius: 3px;

        &:focus {
            box-shadow: none;
        }
    }

    .modal-footer {
        margin-top: 20px;
        padding: 20px 0 0;
        justify-content: space-between;
    }
}

.exam-modal {
    .modal-body {
        padding: 30px;
    }
}

.question-modal,
.exam-modal {
    .modal-body {
        ul {
            list-style: none;
            padding: 0;
            margin-top: 20px;
        }

        li {
            margin-bottom: 15px;

            span {
                color: #4b4b4a;
                font-size: 15px;
            }

            .media-body {
                margin-left: 16px;
                color: #7e7d7b;
            }
        }
    }
}

.question-modal {
    .questions {
        min-height: auto;
    }

    .question-row .tick-radio {
        width: 75%
    }

    .count {
        @include circle-number(20px, auto, #e2e1dd, 10px, 400, 20px, none, 2px);
        padding: 0 5px;
    }

    .modal-body {
        padding: 30px 0 0;

        .info-box {
            @include white-box(#fbf1df, 3px, 20px, 0 0 20px);

            p {
                margin-bottom: 0;
                font-size: 14px;
                color: #4b4b4a;

                span {
                    font-weight: 600;
                }
            }
        }

        h5 {
            color: #323231;
            font-weight: 400;
            line-height: 28px;
        }

        @include upload-file-button();

        .quiz-tip {
            .form-radio {
                position: relative;
                top: auto;
            }

            .form-radio~label:before {
                border: 4px solid red;
            }
        }
    }

    .question-note {
        margin-top: 36px;
        padding-top: 36px;

        span {
            color: #323232;
        }

        p {
            color: #4b4b4a;
            font-size: 15px;
            margin-top: 4px;
        }
    }
}



.ans-option {
    .form-radio {
        width: 15px;
        height: 15px;

        &:before {
            font-size: $baseSize + 1;
        }

        &::checked~label {
            color: rgba(0, 0, 0, 0.8);
        }
    }

    label {
        width: calc(100% - 20px);
        font-size: $baseSize - 1;
        line-height: 15px;
        display: block;
        color: $bodytextcolor !important;

        i {
            float: right;
        }
    }

    .ans-bar {
        margin: 0 -30px;
        padding: 0 30px;

        i {
            visibility: hidden;
        }

        &:hover {
            i {
                visibility: visible;
            }
        }
    }

    .del-icon {
        color: #ff6f61;
    }

    i {
        cursor: pointer;
    }

    .menu {
        @include positionabsolute(-5px, auto, auto, 3px);
        font-size: $baseSize + 7;
    }

    .tag {
        background: $activeBgColor;

        i,
        span {
            color: #fff;
        }

        i {
            opacity: 1;
        }
    }
}

/*Question Accordion*/
.question-accord {
    .card-header {
        padding: 0;
        background: #eeede9;
        border-bottom: none;

        button {
            width: 100%;
            justify-content: flex-start;
            font-size: 18px;
            height: 56px;
            color: #323231;
            text-decoration: none;
            font-weight: 500;
            position: relative;

            &[aria-expanded="true"] {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            &[aria-expanded="false"] {
                border-bottom: 1px solid transparent;
            }

            &:after {
                font: 20px/1 'Material Icons';
                @include positionabsolute(auto, 20px, auto, auto);
                color: #979694;
            }

            &[aria-expanded="true"]:after {
                content: "keyboard_arrow_up";
            }

            &[aria-expanded="false"]:after {
                content: "keyboard_arrow_down";
            }

            i {
                margin-right: 12px;
            }
        }
    }

    .card {
        margin-bottom: 16px;
        border-color: rgba(0, 0, 0, 0.125);
    }

    .card-body {
        background: #fbfaf6;

        .menu {
            font-size: 16px;
        }
    }
}

/* page loader */
.site-loader {
    height: 100vh;
    background: #fbfaf6;
    @include positionabsolute(0, 0, 0, 0);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 999;
    padding: 10px;

    .loader-container {
        max-width: 520px;
        text-align: center;
    }

    p {
        font-weight: 600;
        color: #323232;
        font-size: 20px;
        line-height: 28px;
        margin: 50px 0 10px;
    }

    span {
        color: #979694;
    }

    .circle {
        position: relative;
        display: inline-block;
        padding: 10px;

        div {
            background: url(../images/spinner-2x.png);
            @include positionabsolute(0, 0, 0, 0);
            background-size: cover;
            -webkit-animation: spin 2s linear infinite;
            /* Safari */
            animation: spin 2s linear infinite;
        }
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

//Add new question 
.add-new-question {
    .modal-body {
        padding: 30px;
    }

    .difficulty-level {
        .middle-radio {
            margin: 0 40px;
        }
    }

    h5 {
        font-size: 15px;
    }

    .attached-file {
        margin-bottom: 30px;
    }
}

.add-row {
    @include white-box(#eeede9, 3px, 10px, 10px 0 20px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #979694;
    font-size: 15px;
    cursor: pointer;

    .material-icons {
        font-size: 18px;
    }
}

.type-anser {
    .form-control {
        background: none;
        border-width: 0px 0px 1px 0px;
        border-radius: 0;
        padding: 0;
        height: 24px;
        margin-left: 8px;

        &:focus {
            box-shadow: none;
        }

        &:valid {
            &~.close-icon {
                display: block;
            }
        }

        @include basecolor(0.4);
    }

    span {
        line-height: 1;
        color: #4b4b4a;
    }

    .close-icon {
        position: absolute;
        right: 0;
        display: none;

        i {
            font-size: 18px;
            @include basecolor(0.4);
        }
    }
}

.title-checkboxes {
    background: #eeede9;
    padding: 10px;
    position: relative;
    cursor: pointer;

    .add {
        @include positionabsolute(50%, auto, auto, 50%);
        transform: translate(-50%, -50%);
        color: #979694;
    }

    .tick-radio i {
        position: absolute;
        right: 0;
        top: 3px;
        font-size: 18px;
        color: #979694;
    }

    .image-header {
        width: 130px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;

        img:not(.upload-image-sign) {
            width: 100%;
        }
    }
}

.sorting-rows {
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #e2e1dd;
    margin-top: 30px;

    li {
        display: inline-flex;
        padding: 0 8px;
        position: relative;
        font-size: 15px;
        color: #4b4b4a
    }

    .active {
        font-weight: 600;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background: #979694;
            top: 100%;
            margin-top: 5px;
        }
    }
}

.question-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 13px;
    margin-bottom: 4px;
    cursor: pointer;

    &:not(.no-hover) {
        background: #fbfaf6;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);

        &:hover {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(0, 0, 0, 0.1);
            background: #fff;
        }
    }

    .row-options {
        display: flex;
        align-items: baseline;

        span {
            font-size: 14px;
            min-width: 100px;
            text-align: center;
        }

        &>div:not(.tags) {
            min-width: 140px;
            text-align: right;
        }

        .tags {
            min-width: 50px;
        }
    }

    .tick-radio {
        width: 65%;
    }
}

.user-affect-box {
    background: #e2e1dd;
    padding: 16px 10px 10px;
    border-radius: 3px;

    .user-affect-list {
        background: #fbfaf6;
        border-radius: 2px;
        padding: 12px 16px 16px;
        margin-top: 16px;
    }

}

.course-intro {
    ul {
        padding: 0 0 0 18px;
        color: #646462;

        li {
            padding: 0 0 10px;
        }
    }
}

.exam-options-box {
    background: #fff;

    .option-header {
        background: #eeede9;
        padding: 22px 18px;

        .user-image {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            margin-right: 15px;
        }

        button {
            margin-left: 16px;
            background: #e2e1dd;
            border: none;
            font-size: 10px;
            border-radius: 3px;
        }
    }

    .switch-options {
        padding: 26px 18px;
        border-bottom: 1px solid #e2e1dd;
    }

    @include percentCounter(90px, 90px, 96px, 96px, #fff);

    .clock {
        padding: 20px 10px 26px;
        text-align: center;
        border-bottom: 1px solid #e2e1dd;

        &>span {
            font-size: 11px;
            color: rgba(33, 37, 41, 0.3);
            text-transform: uppercase;
        }

        .active-border {
            margin: 0 auto;
        }
    }

    .calendar {
        margin: 0;
        padding: 30px 20px;

        li {
            display: inline-block;
            margin: 0 !important;

            button {
                width: 24px;
                height: 24px;
                font-size: 12px;
                background: #eeede9;
                border: none;
                border-radius: 3px;
                color: #646462;
                display: inline-block;
                vertical-align: middle;
                line-height: 20px;
                padding: 0;
                margin-bottom: 10px;
                cursor: pointer;

                &.active {
                    background: none;
                    border: 1px solid #c9c8c5;
                }
            }
        }
    }
}

.exam-miss-modal {
    .modal-dialog {
        max-width: 550px;
    }

    .modal-header {
        border-bottom: none;
    }

    h6 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.3);
        font-weight: 400;
        margin: 0;
        line-height: 20px;
    }

    .modal-body {
        h5 {
            font-size: 16px;
            line-height: 32px;
            margin: 0;
        }

        &>p {
            padding-top: 20px;
            line-height: 24px;
            font-size: 14px;
        }
    }

    .form-group {
        label {
            display: block;
        }

        textarea {
            width: 100%;
        }
    }
}

.meesage-container {
    @include custom-col(calc(100vh - 64px), false);
    display: flex;

    .main-sidebar,
    .conversation-window {
        height: calc(100vh - 64px);
        overflow: auto;
    }

    .message-intro {
        padding-right: 25px;
    }

    .message-count {
        @include circle-number(24px, 24px, #39b27b, 12px, 500, 24px, none, 100%);
        color: #fff;
    }

    .side-option {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }

    .media-body {
        position: relative;
    }

    .material-icons {
        color: #979694
    }

    .material-icons.active {
        color: #0099ff;
    }

    .single-messages>* {
        &:not(:last-child) {
            .media-body {
                border-bottom: 1px solid #dee2e6;
                padding-bottom: 15px;
            }
        }
    }
}

.message-input {
    position: fixed;
    width: calc(100% - 320px - 280px);
    bottom: 0;
    font-size: 14px;
    background: #eeede9;

    // input {
    //     height: 56px;
    //     width: 100%;
    //     background: #eeede9;
    //     padding: 0 20px;
    //     border: none;
    //     &::placeholder {
    //         color: #b0afac;
    //     }
    //     &:focus{
    //         outline: none;
    //     }
    // }
    .userinfo {
        padding: 20px;
    }

    div[contentEditable="true"] {
        display: inline-block;
        height: 56px;
        width: calc(100% - 104px);
        // max-height: 100px;
        padding: 15px;
        box-sizing: border-box;
        overflow-y: auto;
        position: relative;

        span {
            color: #b0afac;
            position: absolute;
            top: 22px;
        }

        &:focus {
            outline: none;

            span {
                display: none;
            }
        }
    }
}

.col-container {
    overflow: hidden;
}

.meesage-container {
    .col-label {
        padding-top: 16px;
        padding-left: 16px;
    }

    .col-label,
    .message-people-list label {
        font-size: 12px;
        color: #b0afac;
        text-transform: uppercase;
        display: block;
    }

    .userinfo {
        img {
            height: 32px;
            width: 32px;
        }

        .media-body {
            margin-left: 10px;

            span {
                font-size: 14px;
                font-weight: 500;
            }

            i {
                color: #b0afac;
                font-size: 14px;
                font-style: normal;
            }
        }
    }

    .message-bar {
        padding: 20px;
        max-width: 400px;

        .message-content {
            margin-top: 10px;
            background: #eeede9;
            padding: 12px 16px;
            border-radius: 0px 10px 10px 10px;
            display: inline-block;

            p {
                margin: 0;
                font-size: 14px;
            }
        }

        i {
            margin-left: 10px;
        }
    }
}

.message-people-list {
    label {
        padding: 16px 10px 10px 17px;
    }

    li {
        margin-bottom: 12px;

        &.active:after {
            left: 34px !important;
        }
    }

    .media-body {
        margin-left: 12px;
        width: calc(100% - 48px);

        span {
            font-weight: 500;
            font-size: 14px;
            width: calc(100% - 18px);
            display: inline-block;
            vertical-align: middle;
        }
    }

    .media {
        padding: 0 8px;
    }
}

.conversation-window {
    background: #fbfaf6;

    .conversation {
        justify-content: end;
        height: calc(100% - 56px - 63px);
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;

        &>.userinfo {
            padding: 20px;
        }
    }

    .conversation-header {
        padding: 13px 20px 12px;
        border-bottom: 1px solid #e2e1dd;
    }
}

@include media-breakpoint-up(notMobile) {
    body:not(.active-notification-panel) {
        // overflow: hidden;
    }

    .login-wrapper {
        padding: 40px;
        min-height: calc(100vh);

        .login-block {
            padding: 20px 20px 20px;
            min-height: calc(100vh - 50px - 80px);
            border-radius: 3px;
        }
    }

    .login-block-footer {
        margin: 0 20px;

        a {
            color: $bodytextcolor;
            text-decoration: none;
        }
    }

    .login-block-logo {
        img {
            width: 100%;
        }
    }

    .notification {
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;

        .notification-block {
            transform: translateX(320px);
            @include slideAnimation;
        }
    }

    .active-notification-panel {
        .min-container {
            position: fixed;
        }

        .notification-block {
            transform: translateX(0);
        }
    }

    .notification-bell,
    .hide-notification {
        right: 40px;
        bottom: 40px;
        position: fixed;
    }

    /*Header*/
    header {
        .big-logo {
            height: 72px;
        }

        .menu {
            @include menu(15px, 10px 16px, #fff, 0, inline-block, rgba(0, 0, 0, 0.5), 24px, rgba(0, 0, 0, 0.2));

            &>li {
                &.new-notification {
                    @include new-notification(4px, 4px, auto);
                }

                &:not(:last-child) {
                    margin-right: 12px;
                }
            }
        }

        // .menu {
        //     padding: 0;
        //     margin: 0;
        //     list-style: none;
        //     li {
        //         display: inline-block;
        //         &.new-notification{
        //             @include new-notification(3px,3px,auto);
        //         }
        //     }
        //     a {
        //         color: #fff;
        //         font-weight: 600;
        //         line-height: 24px;
        //         padding: 10px 16px;
        //         display: block;
        //         font-size: 15px;
        //         border-radius: 2px;
        //         &:hover {
        //             text-decoration: none;
        //             background: rgba(0,0,0,0.2);
        //         }
        //     }
        //     .active {
        //         background: rgba(0,0,0,0.5);
        //     }
        // }
        .header-options {
            display: inline-flex;
            align-items: center;

            svg {
                cursor: pointer;

                g {
                    fill: #fff;
                }
            }

            &>* {
                display: inline-flex;
                height: 32px;
                width: 32px;
                align-items: center;
                justify-content: center;

                svg,
                .material-icons {
                    opacity: 0.8;
                }

                &:hover {
                    opacity: 1;
                }
            }

            .material-icons {
                color: #fff;
                cursor: pointer;
            }

            .message-dropdown {
                margin: 0 16px;
                // img {
                //     display: block;
                //     cursor: pointer;
                // }
            }

            .plus-dropdown {
                margin: 0 8px;
            }

            .user-dropdown {
                padding-left: 18px;
                border-left: 1px solid rgba(255, 255, 255, 0.2);
                cursor: pointer;
                width: auto;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                }
            }
        }
    }

    .media {
        h6 {
            width: 100%;
        }
    }

    .lesson-header {
        .media-body {
            margin-left: 30px;
        }
    }

    .tab-header {
        .nav {
            border-top: 1px solid #e2e1dd;
            border-bottom: none;
            padding: 11px 0;
        }

        a.active {
            &:after {
                content: "";
                height: 2px;
                background: #191919;
                position: absolute;
                top: -12px;
                left: 0;
                right: 0;
            }
        }

        .dropdown-menu {
            position: static !important;
            display: block;
            float: none;
            padding: 0;
            margin: 0;
            border: none;
            background: none;
            transform: none !important;
        }
    }

    .carousel-slide {
        min-width: 350px;
    }

    .discussion-content {
        padding: 48px 0 0 97px;

        .content-box {
            padding: 0px 60px 32px;
        }

        .course-footer {
            padding: 16px 68px 16px 24px;
        }

        .progressed-bar {
            padding: 0 70px 24px;
        }
    }

    .content-container {
        overflow: hidden;

        &>* {
            float: left;
        }

        &:not(.announcement) {
            .main-sidebar {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .main-sidebar {
            width: 320px;
            min-height: 100vh;
        }

        .tabs-section {
            padding-left: 34px;
            width: calc(100% - 320px);
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: auto;
                width: 320px;
                right: 100%;
                background: #eeede9;
                top: 0;
                bottom: 0;
                z-index: -1;
                border-right: 1px solid #fbfaf6;
            }
        }
    }

    .progressed-bar {
        position: relative;

        &:after {
            content: "";
            background: rgba(0, 0, 0, 0.1);
            height: 1px;
            position: absolute;
            top: 18px;
            right: 70px;
            left: 70px;
        }
    }

    .modal-from-right {
        .tab-content {
            padding: 32px;
        }

        .modal-header .close {
            margin-left: -65px;
            top: auto;
            right: auto;
            bottom: auto;
            left: 0;
        }

        .modal-dialog {
            max-width: 700px;
        }
    }

    .content-box.black-bg {
        padding-top: 78px !important;
        padding-bottom: 78px !important;
    }

    .upload-file-display {
        li {
            max-width: 165px;
        }
    }

    .question-modal {
        .modal-dialog {
            max-width: 730px;
        }
    }

    &#session {
        margin: -32px
    }
}

.exam-date {
    p {
        font-size: 14px;
        color: #b0afac;
    }
}

.small-switch {
    @include switch(38px, 20px, 16px, 16px, #319b6b, false);

    .switch {
        vertical-align: middle;
    }

    label {
        margin: 0 10px 0 0;
    }

    span {
        font-size: 15px;
    }
}

.exam-modal-stats {
    .stats-bar-block {
        h5 {
            margin: 0;
            line-height: 32px;
        }

        .table td {
            padding-bottom: 0;
            border: none;

            &:last-child {
                color: #4b4b4a;
            }

            &:first-child {
                font-weight: 600;
            }
        }

        span {
            color: rgba(25, 25, 25, 0.5);
        }
    }
}

@include media-breakpoint-up(desktop) {
    .lesson-tabs {
        .tab-header {
            li:not(:last-child):not(:first-child) {
                margin-right: 30px;
            }

            li:first-child {
                margin-right: 20px;
            }
        }
    }

    .carousel-slide {
        margin: 0 0 74px;
        height: calc(100% - 74px);
    }

    #examStatsModal .modal-dialog {
        max-width: calc(1110px - 64px)
    }

    .modal-from-right {
        .modal-dialog {
            max-width: 50%;
        }

        .tab-content {
            .tab-pane {
                display: block;
                position: absolute;
                -moz-transition: width 0.3s ease-in-out;
                -o-transition: width 0.3s ease-in-out;
                -webkit-transition: width 0.3s ease-in-out;
                transition: width 0.3s ease-in-out;
                width: 600px;
                top: -100%;
                z-index: -1;
            }
        }

        .tab-pane.active {
            position: relative;
            z-index: 9;
            top: auto;

            &#video {
                width: calc(1100px - 64px)
            }

            &#profile,
            &#exam-date {
                width: calc(720px - 64px);
            }

            &#contact,
            &#home {
                width: calc(600px - 64px);
            }

            &#session {
                width: calc(960px - 65px);
            }
        }
    }

    #listMakeRequest {
        .modal-dialog {
            width: calc(100% - 64px);
        }

        .modal-body {
            min-height: calc(100vh - 63px);
        }

        .create-announcement {
            max-width: 550px;
            margin: 0 auto;
            border-left: none;
        }
    }

    .question-accord {
        .card-body {
            padding: 32px 24px;
        }
    }

    .add-announcement {
        .modal-dialog {
            max-width: 720px;
            width: 100%;
        }

        .modal-body {
            padding: 30px;
        }
    }
}

@include media-breakpoint-up(ipad) {
    .lesson-tabs {
        .tab-header {
            li:not(:last-child):not(:first-child) {
                margin-right: 15px;
            }

            li:first-child {
                margin-right: 10px;
            }
        }
    }
}

@include media-breakpoint-up(notDesktop) {
    .carousel-slide {
        margin-top: 30px;
    }

    .question-accord {
        .card-body {
            padding: 20px 15px;
        }
    }
}

@include media-breakpoint-up(mobile) {

    .login-block-logo {
        img {
            display: block;
            width: 100%;
            max-width: 220px;
            margin: 0 auto;
        }
    }

    html,
    body {
        overflow-x: hidden;
    }

    .login-wrapper {
        width: 200%;
        padding: 15px;

        &>.row {
            transform: translateX(0);
            @include slideAnimation;
        }

        .login-block {
            border-radius: 3px 3px 0 0;
            padding: 20px 20px 30px;
            min-height: calc(100vh - 50px - 20px);
        }
    }

    .notification {
        .notification-block:not(:nth-child(-n+2)) {
            display: none;
        }

        overflow: hidden;
    }

    .active-notification-panel {
        .login-wrapper {
            &>.row {
                transform: translateX(calc(-50% + 30px));
            }
        }

        .notification {
            height: auto;

            .notification-block {
                display: block;
            }
        }
    }

    .notification-bell,
    .hide-notification {
        right: 20px;
        bottom: 20px;
        position: fixed;
    }

    .menu-button {
        background: none;
        color: #fff;
        opacity: 0.7;
        float: right;

        &:hover {
            opacity: 1;
        }
    }

    .mobile-menu {
        @include close-button(-48px, 0, 15px 7px 2px);
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        background: #fbfaf6;
        left: 47px;
        bottom: 0;
        z-index: 9;

        &:after {
            position: absolute;
            content: "";
            right: 100%;
            bottom: 0;
            top: 0;
            background: #000;
            opacity: 0.6;
            width: 100%;
        }

        .menu {
            @include menu(17px, 12px 25px, rgba(0, 0, 0, 0.9), 0, block, #e2e1dd, 24px, #eeede9);

            .new-notification {
                @include new-notification(50%, auto, 6px);

                &:after {
                    margin-top: -3px;
                }
            }
        }
    }

    .header-options {
        position: absolute;
        bottom: 0;
        background: rgba(250, 250, 250, 0.9);
        left: 0;
        right: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-around;
        padding: 9px 0;
        text-align: center;
        align-items: flex-end;

        .new-notification {
            @include new-notification(0, 0, auto);

            &:after {
                margin-top: -3px;
            }
        }

        .plus-dropdown {
            display: none;
        }

        .user-dropdown {
            img {
                display: none;
            }

            svg {
                margin-bottom: 10px;
            }
        }

        span {
            font-size: 10px;
            color: #323231;
        }

        svg g {
            fill: rgba(0, 0, 0, 0.7);
        }
    }

    .lesson-header {
        .media {
            display: block;
            text-align: center;

            .media-body {
                margin-top: 15px;
            }
        }
    }

    .lesson-tabs .tab-header {
        .dropdown-menu {
            padding: 0 15px;
            margin: 0;
            border-radius: 0 0 4px 4px;
        }

        .nav {
            // position: absolute;
            // z-index: 99;
            // background: #fff;
            // left: 15px;
            // right: 15px;
            // border: solid #191919;
            // padding: 0 15px;
            // border-width: 0px 1px 1px 1px;
            // border-radius: 0 0 4px 4px;

            li {
                width: block;
                width: 100%;
                padding: 5px 0 0;

                &:last-child {
                    padding-bottom: 10px;
                }

                &.new-notification:after {
                    top: 17px;
                }
            }

        }

        [data-toggle="dropdown"] {
            &[aria-expanded="true"] {
                background: $activeBgColor;
                color: #fff;
                border-color: $activeBgColor;
                border-radius: 4px 4px 0px 0px;
            }

            &[aria-expanded="false"] {
                background: none;
                border: 1px solid #191919;
                color: #191919;
            }

            box-shadow: none !important;
            width: 100%;
            height: 48px;
        }
    }

    .lesson-tabs.studen-tabs .tab-content {
        padding-bottom: 30px;
    }

    .discussion-content {
        padding: 0;

        .content-box {
            padding: 0px 24px 32px;
        }

        .course-footer {
            padding: 20px 24px;
        }

        .progressed-bar {
            padding: 0 24px 24px;
            text-align: center;
        }

        .count {
            margin-top: 20px;
        }
    }

    .main-sidebar {
        display: none;
    }

    .modal-header .close {
        right: 0;
        margin: 0;
        top: 0;
    }

    .modal-from-right {
        .tab-content {
            padding: 16px;
        }

        .modal-footer {
            button {
                width: 100%;
            }
        }
    }

    .upload-file-display {
        li {
            width: 45%;

            img {
                width: 100%;
            }
        }
    }

}

.react-switch {
    vertical-align: middle;
    margin-left: 16px;
    float: right;
    transform: scale(0.8);
}

//Home page Student course Css Start

.student-course {
    background-color: #eeede9;

    .student-course-header {
        padding: 30px 0;

        h2 {
            color: #191919;
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.8;
        }

        a {
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.85;
            letter-spacing: normal;
            color: #646462;

            &:hover {
                text-decoration: none;
                color: #007bff;
            }
        }
    }
}

.student-course-list {
    margin-bottom: 30px;

    .list-icons {

        a.notify-dot:after,
        i.notify-dot:after {
            width: 6px;
            height: 6px;
            background: #ff6f61;
            position: absolute;
            left: auto;
            right: -6px;
            top: -4px;
            content: "";
            border-radius: 100%;
        }
    }
}

.student-course .student-course-list li {
    list-style: none;
    padding: 10px 10px;
    background-color: #fbfaf6;
    margin-bottom: 4px;
}

.student-course-list,
.course-timeline-item {
    .active-border {
        background-image: linear-gradient(3.6deg, #28a745 50%, transparent 50%, transparent), linear-gradient(270deg, #28a745 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1));
        text-align: center;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle {
        font-size: 12px;
        color: rgba(50, 50, 49, 0.5);
        text-align: center;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        background-color: #fbfaf6;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.student-course .student-course-list li .list-icons {
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.list-icons:hover>.student-course .student-course-list li {
    background-color: #000;
}

.student-course .student-course-list li {
    .course-icon {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
    }

    h5 {
        color: #323231;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        margin-bottom: 5px;
    }

    p {
        color: #323231;
        opacity: 0.5;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: normal;
        margin: 0;
    }
}

//student-card student-discussions

.lead-bar {
    background: rgba(0, 0, 0, 0.05);
    height: 6px;
    border-radius: 32px;
    position: relative;
}

.lead-bar-width {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #39b27b;
    border-radius: 32px;
}

.lead-bar-text {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: -0.5px;
    color: #646462;
}

.scrollable-popoverbody {
    max-height: 256px;
    overflow: auto;
    width: 100%;
}


::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

:hover::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    opacity: 0.1;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}


button {
    display: inline-flex;
    height: var(--Height-xx-large, 40px);
    padding: 0px var(--Spacing-large, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-small, 8px);
    white-space: nowrap;
    font-size: medium;

    &.primary {
        border-radius: var(--Radius-regular, 8px);
        background: var(--Color-Action-Primary, #0D2145);
        color: var(--Color-Glyph-Inverted, #FFF);

        &:hover:not(:disabled) {
            border-radius: var(--Radius-regular, 0.5rem);
            background: var(--Color-State-Hover-Primary, #0F152A);
            color: var(--Color-Glyph-Inverted, #FFF);
        }
    }

    &.tertiary {
        border-radius: var(--Radius-regular, 0.5rem);
        border: 1px solid var(--Color-Border-Secondary, #E8E9EB);
        background: var(--Color-Action-Tertiary, #FFF);
        color: var(--Color-Action-Primary, #0D2145);

        &:hover:not(:disabled) {
            border-radius: var(--Radius-regular, 0.5rem);
            background: var(--Color-State-Hover-Secondary, #CFD3D4);
        }

    }

    &.ghost {
        border-radius: var(--Radius-regular, 0.5rem);
        color: var(--Color-Action-Primary, #0D2145);

        &:hover:not(:disabled) {
            border-radius: var(--Radius-regular, 0.5rem);
            background: var(--Color-State-Hover-Secondary, #CFD3D4);
        }

    }

    &:disabled {
        border-radius: var(--Radius-regular, 0.5rem);
        border: 1px solid var(--Color-Glyph-Tertiary, #F0F0F0);
        background: var(--Color-State-Disabled-Primary, #F0F0F0);
        color: var(--Color-State-Disabled-Primary, #BBBBBB);
    }
}

.primary-glyph {
    font-size: 16px;
    color: #111111;
    padding-bottom: 0.5rem;
}